import styled from 'styled-components';
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import SectionHero from "../components/section-hero";
import VideoSlider from '../components/video-slider';
import VideoGallery from '../components/video-gallery';

import YoastSeoParts from '../fragments/seo';

class PageTemplate extends Component {

  render() {
    const page = this.props.data.wpPage;
    const { seo } = page
    const { featuredVideos, videoCategories, pageAccentColor } = page.template.templateVideoResources;

    return (
      <Layout>
        <PageWrapper accentColor={pageAccentColor || '#21c6be'}>
          <Head title={seo.title} description={seo.metaDesc} meta={seo} />

          <SectionHero section="resource" title={page.title}/>

          { featuredVideos &&
            <VideoSlider title={featuredVideos.name} videos={featuredVideos.contentNodes.nodes} />
          }

          { videoCategories &&
            videoCategories.map(({id, name, contentNodes}) => {

              return (
                <VideoGallery key={id} title={name} videos={contentNodes.nodes} />
              )
            })
          }
        </PageWrapper>
      </Layout>
    )
  }
}

const PageWrapper = styled.div`
  .defaultHero .dots span {
    color: ${({ accentColor }) => accentColor};
  }

  .defaultHero {
    border-top-color: ${({ accentColor }) => accentColor};
  }

  hr {
    border-color: ${({ accentColor }) => accentColor} !important;
  }
`

export default PageTemplate

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      link
      seo {
        ...seoParts
      }
      template {
        ... on WpTemplate_VideoResources {
          templateVideoResources {
            pageAccentColor
            featuredVideos {
              name
              contentNodes {
                nodes {
                  ... on WpVideo {
                    id
                    cptVideos {
                      videoId
                    }
                  }
                }
              }
            }
            videoCategories {
              id
              name
              contentNodes {
                nodes {
                  ... on WpVideo {
                    id
                    title
                    cptVideos {
                      videoId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`